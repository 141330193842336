<template>
  <transition name="fade">
    <swiper v-if="isHome" :options="swiperOption">
      <swiper-slide v-for="(item, index) in list" :key="index">
        <a :href="item.obj_id" target="_blank">
          <div
            class="swiper-wrap"
            v-lazy:background-image="item.url"
            :style="{height:swiperHeight }"
          >
            <div class="character">
              <div class="title" data-swiper-parallax="-800">{{item.title}}</div>
              <br />
              <div class="subtitle" data-swiper-parallax="-1600"></div>
            </div>
            <!-- <img :src="item.url" alt="" > -->
          </div>
        </a>
      </swiper-slide>

      <div class="swiper-pagination" slot="pagination"></div>
      <!-- <div class="swiper-button-prev" slot="button-prev"></div> -->
      <!-- <div class="swiper-button-next" slot="button-next"></div> -->
    </swiper>
  </transition>
</template>

<script>
import { http } from "@/api/http";
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import Api from "@/api";
import backUpImg from "@/assets/banner.jpg";

export default {
  name: "swiper_top",

  created() {
    this.getList();
  },
  watch: {
    $route(to, from) {
      window.scroll(0, 0);
      if (to.name === "home") {
        this.isHome = true;
      } else {
        this.isHome = false;
      }
    }
  },

  data() {
    return {
      swiperOption: {
        pagination: {
          el: ".swiper-pagination",
          bulletActiveClass: "home-bullet-active",
          bulletClass: "home-bullet",
          clickable: true
        },
        speed: 2000,
        parallax: true,

        loop: true,
        autoplay: {
          delay: 5000
        }
      },
      list: [],
      isHome: true,
      swiperHeight: document.documentElement.clientWidth / (192 / 70) + "px"
    };
  },
  mounted() {
    var _this = this;
    window.onresize = function() {
      // 定义窗口大小变更通知事件
      _this.swiperHeight =
        document.documentElement.clientWidth / (192 / 70) + "px"; //窗口高度
    };
  },
  components: {
    swiper,
    swiperSlide
  },
  methods: {
    async getList() {
      this.$emit("toggleLoading", true);
      let { data } = await http.get("/banner", { position: 1 });
      this.list = data;
    }
  }
};
</script>
<style lang="scss" scoped>
.swiper-container {
  // height: 700px;
  // min-width: 1200px;
  top: -95px;
}
.swiper-wrap {
  width: 100%;
  min-width: 1200px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: rgba(248, 248, 248, 0.8);
  .character {
    position: absolute;
    top: 345px;
    color: #b58c66;
    left: 180px;
    .title {
      font-size: 100px;
      font-weight: bolder;
    }
    .subtitle {
      font-size: 25px;
      font-weight: 400;
    }
  }
}
.fade-leave-active,
.fade-enter-active {
  transition: all 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-700px);
}
</style>
