import qs from 'qs';
import service from './service'


const http = {
  async get (url, data) {
    try {
      let res = await service.get(url, {params: data})
      res = res.data
      return new Promise((resolve) => {
        if (res.code === 0) {
          resolve(res)
        } else {
          resolve(res)
        }
      })
    } catch (err) {
      console.log('服务器出错')
      console.log(err)
    }
  },
  async post (url, data) {
    try {
      let res = await service.post(url, qs.stringify(data))
      res = res.data
      return new Promise((resolve, reject) => {
        if (res.code === 0) {
          resolve(res)
        } else {
          reject(res)
        }
      })
    } catch (err) {
      // return (e.message)
      console.log('服务器出错')
      console.log(err)
    }
  },
}
export { http }