<template>
  <div class="top-part">
    <el-card class="box-card">
      <div class="detail-info">
        <a href="https://www.720yun.com/vr/52cjkrtnek5" target="_blank">
        <div class="detail-info-img">
          <div class="img">
            <img src="../../../../assets/360.png" alt />
          </div>
          <div class="title">
            <p>360° Panorama Gallery</p>
            <p>360度线上展馆</p>
          </div>
        </div>
</a>
        <div class="detail-info-nav">
          <div class="inner inner1" :class="{'active':active!==1}" @mouseenter="mouseInItem(1)">
            <div class="item1">
              <router-link active-class="router-active" exact to="/business">
              <p>Partners</p>
              <p>合作伙伴</p>
              </router-link>
            </div>
          </div>
          <div class="inner inner2" :class="{'active':active!==2}" @mouseenter="mouseInItem(2)">
            <div class="item2">
              <a active-class="router-active" target="_blank" href="https://gptcda.cdcdw.com.cn/portal/product/index.html">
              <p>Archive</p>
              <p>作品赏析</p>
              </a>

            </div>
          </div>
          <div class="inner inner3" :class="{'active':active!==3}" @mouseenter="mouseInItem(3)">
            <div class="item3">
              <router-link active-class="router-active" exact to="/about">
                <p>About Us</p>
                <p>关于我们</p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "top_part",
  components: {},
  data() {
    return {
      active: 0
    };
  },
  methods: {
    mouseInItem(index) {
      this.active = index;
    }
  }
};
</script>
<style lang="scss" scoped>
.top-part {
  margin-top: 30px;
  .box-card {
    border-radius: 0;
    .detail-info {
      padding: 80px 100px 80px 60px;
      overflow: hidden;
      position: relative;
      &-img {
        width: 660px;
        height: 520px;
        box-sizing: border-box;
        padding: 30px;
        background: #e3e3e3;
        position: relative;
        float: left;
        .img {
          width: 600px;
          height: 450px;
          position: relative;
          background: url("../../../../assets/360.jpg") no-repeat center center;
          background-size: cover;
          img {
            position: absolute;
            margin: 0 auto;
            width: 62px;
            height: 62px;
            bottom: 100px;
            left: 0;
            right: 0;
          }
        }
        .title {
          height: 130px;
          width: 660px;
          background: rgba(248, 248, 248, 0.95);
          position: absolute;
          bottom: 0;
          left: 0;
          color: #737373;
          font-size: 30px;
          text-align: center;
          font-weight: bold;
          p {
            margin: 0;
            &:first-child {
              margin: 25px 0 20px;
            }
          }
        }
      }
      &-nav {
        float: right;
        .inner {
          transition: all 0.4s;
          div {
            width: 250px;
            height: 130px;
            box-sizing: border-box;
            padding: 0 55px;
            margin: 4px;

            p {
              font-size: 24px;
              color: #ffffff;
              font-weight: 500;
              width: 100%;
              word-wrap: break-word;
              word-break: normal;
              text-align: center;
              margin: 0;
              &:first-child {
                padding-top: 30px;
                margin-bottom: 10px;
              }
            }
          }
        }
        .inner1 {
          border: 2px solid #3b239b;
        }

        .inner2 {
          border: 2px solid #ecdd31;
          margin: 50px 0;
        }

        .inner3 {
          border: 2px solid #d5d4d4;
        }

        .item1 {
          background: #3b239b;
        }
        .item2 {
          background: #ecdd31;
         
        }
        .item3 {
          background: #d5d4d4;
        }
        .active {
          border: 2px solid transparent !important;
        }
      }
    }
  }
}
</style>
