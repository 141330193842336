import axios from 'axios'
import store from '../store'

const service = axios.create({
  baseURL: 'http://shejizhou.cdsbtz.com/api/v2',
  timeout: 5000 // 请求超时时间
})

service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    console.log(error) // for debug
    Promise.reject(error)
  }
)
export default service