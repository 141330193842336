import qs from 'qs';
import service from './service'
class Api {
  constructor() {

  }

  sendCode(param) {
    return new Promise((resolve, reject) => {
      let url = '/verifycodes/register';
      service.post(url, qs.stringify(param))
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  reg(param) {
    return new Promise((resolve, reject) => {
      let url = '/users';
      for (var key in param) {
        if (param[key] === '') {
          delete param[key];
        }
      }
      service.post(url, qs.stringify(param))
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  login(param) {
    return new Promise((resolve, reject) => {
      let url = '/auth/login';
      for (var key in param) {
        if (param[key] === '') {
          delete param[key];
        }
      }
      param.login = param.phone;
      service.post(url, qs.stringify(param))
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  getUser(data) {
    return new Promise((resolve, reject) => {
      let url = '/user';
      service.get(url, {
        headers: {
          'Authorization': data.token_type + ' ' + data.access_token,
          'X-Version': 'v1.0',
          'X_OS': 'IOS'
        }
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });

  }

  getBanner() {
    return new Promise((resolve, reject) => {
      let url = '/banner';
      service.get(url)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  //获取图文列表
  getNewsList(param) {
    return new Promise((resolve, reject) => {
      let url = '/designweek/api/news/list';
      service.get(url,{params:param})
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}

export default new Api();
