var name1 = 'Dates & Deadlines';
var name2 = 'Press Room';
var name3 = 'Downloads';
if (window.location.href.indexOf('en.') == -1) {
   name1 = '活动进程';
   name2 = '新闻资讯';
   name3 = '素材下载';
}
const setting = {
  enrollNav:[
    {
      ch: "金熊猫天府创意设计奖",
      en: "Golden Panda Tianfu Creative Design Award",
      link: "/enroll/slider1",
    },
    {
      ch: "成都创意设计产业展会",
      en: "Chengdu Creative Design Industry Exhibition",
      link: "/enroll/slider2",

    },
    {
      ch: "系列配套活动",
      en: "Supporting Activities",
      link: "/enroll/slider3",

    }
  ],

  mainSlider:{
    nav:[
      {
        ch: "成都创意设计周",
        en: "Chengdu Creative Design Week",
        link: "/activity/slider1",
        itemId: 1
      },
      {
        ch: "金熊猫天府创意设计奖",
        en: "Golden Panda Tianfu Creative Design Award",
        link: "/activity/slider2",
        itemId: 2
      },
      {
        ch: "成都创意设计产业展会",
        en: "Chengdu Creative Design Industry Exhibition",
        link: "/activity/slider3",
        itemId: 3
      },
      {
        ch: "iF成都创意设计论坛",
        en: "IF Chengdu Creative Design Forum",
        link: "/activity/slider4",
        itemId: 4
      },
      {
        ch: "新旅游·潮成都",
        en: "Tour In Trendy Chengdu",
        link: "/activity/slider5",
        itemId: 5
      }
    ]
  },
  
  slider2: {
    nav: [
      {
        name: name1,
        link: "/activity/slider2/2/1/tab1"
      },
      {
        name: name2,
        link: "/activity/slider2/2/2/tab2"
      },
      {
        name: name3,
        link: "/activity/slider2/0/1/tab3"
      }
    ]
  },
  slider3: {
    nav: [
      {
        name: name1,
        link: "/activity/slider3/3/1/tab1"
      },
      {
        name: name2,
        link: "/activity/slider3/3/2/tab2"
      },
      {
        name: name3,
        link: "/activity/slider3/0/2/tab3"
      }
    ]
  },
  slider4: {
    nav: [
      {
        name: name1,
        link: "/activity/slider4/4/1/tab1"
      },
      {
        name: name2,
        link: "/activity/slider4/4/2/tab2"
      },
      {
        name: name3,
        link: "/activity/slider4/0/3/tab3"
      }
    ]
  },
  slider5: {
    nav: [
      {
        name: name1,
        link: "/activity/slider5/5/1/tab1"
      },
      {
        name: name2,
        link: "/activity/slider5/5/2/tab2"
      },
      {
        name: name3,
        link: "/activity/slider5/0/3/tab3"
      }
    ]
  }
}


export default setting