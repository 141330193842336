export default {
  main:{
    LOGIN:'登录',
    REG:'注册',
    LOGINx:'登录',
    REGx:'注册',
    NUCNA:'我还没有账户，请点击',
    PHONE:'手机号',
    PWD:'密码',
    ATRT:'登录即代表您已同意网站用户注册协议与隐私政策',
    ASUPLI:'已有账户，请点击',
    PTYPNHFL:'请输入手机号，用于登录',
    PTYCPN:'请输入正确的手机号',
    PTTVCH:'请输入验证码',
    SAVC:'发送验证码',
    PTAPH:'请输入密码',
    PRYPH:'请再次输入密码',
    PTYNH:'请输入您的姓名',
    PTYFOE:'请提供您的所在行业',
    PTYEAH:'请输入您的邮箱',
    SOUT:'退出',
    SOUTx:'退出',
    FPWD:'忘记密码？',
    PTYINOBLN:'请输入您的个人身份证或公司营业执照',
    PTPYCN:'请输入您的公司名称',
    YPACPDNM:'两次输入密码不一致!',
    PTPPN:'请输入联系电话',
    PTYCN:'请输入公司',
    IOC:'请选择身份性质',
    PTYCEA:'请输入正确的邮箱地址',
    RLATERA:'',
    RLATERB:'s后重新发送',
    RAVC:'重新发送验证码',

   },
  lang: {
    EN: 'EN',
    CH: '中文',
  },
  navbar: {
    CDCDW:'成都创意设计周',
    HELLO:'您好，',
    HOME: '首页',
    ABOUT: '关于',
    ENROLL: '报名',
    ACTIVITY:'活动详情',
    APPRECIATE:'作品欣赏',
    HISTORY:'往届回顾',
    
    PANORAMIC:'360度全景赏析',
    DATESDEADLINES:'活动进程',
    PRESSROOM:'新闻资讯',
    DOWNLOADS:'素材下载',
    STORE:'企业超市',
    GALLERY:'360线上展馆',
    ARCHIVE:'作品赏析',
    PARTNERS:'合作伙伴',
    RINFORMATION:'报名须知',
  },
  others: {
    ATTACHMENTS:'相关附件下载',
    AFORM:'下载报名表',
    SFTOATPOA:'下载原创声明及授权书',
    NMDIA:'暂无更多数据',
    LOADING:'加载中...',
    NOTICE:'提示',
    RMORE:'阅读全文',
    VMORE:'查看更多',
    INDIVIDUAL:'个人',
    COMPANY:'公司',
    MOREW:'更多信息',
    CSOON:'敬请期待',
      },
  business: {
    BPARTNERS:'合作伙伴',
      },
  deigndata: {
    DEIGNNAME:'作品名称',
    DESIGNNO:'作品编号',
    DESIGNER:'作者',
    DATEOFDESIGN:'作品时间',
    INTRODUCTION:'简介',
    IMAGES:'作品截图',
  },
  test: {
    HOME: '这个是首页',
    ABOUT: '这个是关于我们页面',
    ENROLL: '这个是报名页面',
    TIP: '点击lang按钮尝试切换语言',
  },
  activity: {
    FABUTIME: '发布时间',
    XGFUJIAN: '相关附件',
    DJXIAZAI: '点击下载',
    SYIPIAN : '上一篇',
    XYIPIAN : '下一篇',
    TNAME :'名称',
    MEDIA :'专业媒体',
  },
  works: {
    ALL: '全部',
  },
  about: {
    GYOUR: '关于我们',
    GYOURx: 'About Us',
    PREA: '按照习近平总书记来川视察重要讲话精神，为贯彻落实国务院《关于推进文化创意和设计服务于相关产业融合发展的若干意见》（国发﹝2014﹞10号），由成都市人民政府主办的成都创意设计周于2014年起每年在成都举办，目前已成功举办五届，并取得了丰硕成果。',
    PREB: '自2014年起，成都创意设计周累计吸引30多个国家（地区）的近3000家创意设计机构和专业设计人士参加，共征集创意设计奖项作品13000余件，展览面积累积达22万平方米，累计展示创意设计作品超15万件，接待观众超200万人次，以成都为中心掀起了关注创意设计、参与创意的风潮，在中西部地区率先树立了创意设计产业全国知名品牌，广泛运用新媒体和专业媒体，进一步面向国内外传播了“创新创造、优雅时尚、乐观包容、友善公益”的天府文化精神。',
    PREC: '据今日头条数据显示，成都创意设计周影响力位居全国前三。目前已成为成都市具有一定影响力的创意设计自主品牌，为推动成都市文化创意和设计服务与相关产业融合发展奠定了基础，为成都高水平建设世界文创名城发挥引领带动作用。',
  },
  bottom: {
    MWEB: '移动版',
    COFU: '用户协议',
    PPOLICY: '隐私政策',
    IPCOMPLAINTS: '侵权申诉',
    ESERVICES: '企业服务',
    HELP: '帮助中心',
    CONTACTUS: '联系我们',
    LUG: '中文',
    EN: 'English',
    IPC1:'蜀icp备案号：19013477号',
    IPC: '版权所有（C）2005-2019成都一想网络科技有限责任公司版权所有 All Rights Reserved',
  },
  previewImg: {
    previewa: '不能再放大了哦！',
    previewb: '不能再缩小了哦！',
    previewc: '前面已经没有了！',
    previewd: '已经是最后一张了哦！',
  },
  session:{
    all:'全部',
    first:'第一届',
    second:'第二届',
    third:'第三届',
    fourth:'第四届',
    fifth:'第五届',
    sixth:'第六届',
	seventh:'第七届',
	eighth:'第八届',
	ninth:'第九届',
	tenth:'第十届'
  }
};

