import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLangZh: true,
    isLogin: false,
    dialogState: false,
    openLogin: false,
    userName: '',
    listLoading:false
  },
  mutations: {
    changeLang(state, isLangZh = false) {
       state.isLangZh = isLangZh
    },
    changeLoginState(state, login = false) {
      state.isLogin = login
    },
    logout(state, login = false) {
      localStorage.removeItem("hasLogin");
      localStorage.removeItem("userName");
      state.isLogin = login
    },

    closeLogin(state) {
      state.dialogState = false
    },

    openLogin(state, openLogin = true) {
      state.dialogState = true
      state.openLogin = openLogin
    },

    changeUsername(state, userName) {
       state.userName = userName
    },
    openListLoading(state,loadinState){
      state.listLoading = loadinState
    }
  },
  actions: {
    changeLoginAction({ commit }, {loginState = false, access_token}) {
      localStorage.setItem(
        "hasLogin",
        JSON.stringify(access_token)
      );
      commit('changeLoginState', loginState)
    },
    logoutAction({ commit }, login = false) {
      commit('logout', login)
    },
    openLoginAction({ commit }, openLogin = true) {
      commit('closeLogin', true)
      commit('openLogin', openLogin)
    },
    changeUsernameAction({ commit }, userName) {
      localStorage.setItem("userName", JSON.stringify(userName));
      commit('changeUsername', userName)

    }


  },
});
