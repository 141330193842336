<template>
  <div class="center_part">
    <el-card class="box-card">
      <div class="detail-info">
        <div class="color-wrap color-wrap-1">
          <router-link active-class="router-active" exact to="/enroll">
            <div class="color-wrap-text">
              <h3>Registration</h3>
              <h2>报名</h2>
            </div>
            <div class="color-wrap-inner"></div>
          </router-link>
        </div>
        <div class="color-wrap color-wrap-2">
          <router-link active-class="router-active" exact to="/partner">
          <div class="color-wrap-text">
            <h3>Store</h3>
            <h2>企业超市</h2>
          </div>
          <div class="color-wrap-inner"></div>
          </router-link>
        </div>
        <div class="color-wrap color-wrap-3">
          <router-link active-class="router-active" exact to="/history">
          <div class="color-wrap-text">
            <h3>
              Review 
            </h3>
            <h2>往期回顾</h2>
          </div>
          <div class="color-wrap-inner"></div>
          </router-link>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "top_part",
  components: {}
};
</script>
<style lang="scss" scoped>
.center_part {
  margin-top: 30px;
  .box-card {
    border-radius: 0;
    .detail-info {
      padding: 60px;
      overflow: hidden;
      position: relative;
      .color-wrap {
        width: 330px;
        height: 260px;
        float: left;
        // padding: 15px 12px;
        box-sizing: border-box;
        font-size: 21px;
        color: #fff;
        text-align: center;
        position: relative;
        cursor: pointer;
        a {
          color: #fff;
        }
        .color-wrap-text {
          position: absolute;
          bottom: 100px;
          width: 100%;
          z-index: 2;
          transition: all 0.2s;
        }
        .color-wrap-inner {
          transition: all 0.2s;
           width: 330px;
        height: 260px;
        }

        &:hover {
          .color-wrap-text {
            // color: #000;
            transform: translate3d(10px,0,0);

          }
          .color-wrap-inner {
            transform: translate3d(10px, 0px, 500px);
            // box-shadow: 5px 5px 10px #333333;
          }
        }
        h3 {
          margin: 0;
          font-size: 21px;
        }
        h2 {
          margin: 5px 0;
          font-size: 21px;
        }
      }
      .color-wrap-1 {
        .color-wrap-inner {
           background: url("../../../../assets/item1.png");
        background-size: cover;
        }
      }
      .color-wrap-2 {
        margin: 0 24px;

        .color-wrap-inner {
            background: url("../../../../assets/item2.png");
        background-size: cover;
        }
      }
      .color-wrap-3 {

        .color-wrap-inner {
           background: url("../../../../assets/item3.png");
        background-size: cover;
        }
      }
    }
  }
}
</style>
