<template>
  <!-- <div id="app" :style="{minWidth: navWidth+'px'}"> -->
    <div id="app">
    <Header ref='header_nav'/>
    <Swiper  />
    <div class="main-page">
      <router-view @openLoginFun="openLoginFun"/>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "./components/Header_Nav";
import Footer from "./components/Bottom_Nav";
import Swiper from "./components/Swiper_Top";

export default {
  name: "Main",
  data() {
    return {
      navWidth: 1250,
      isHome: true,
    };
  },
  components: {
    Header,
    Footer,
    Swiper
  },
  // watch: {
  //   $route(to, from) {
  //     window.scroll(0, 0);
  //     if (to.name === "home") {
  //       this.isHome = true;
  //       // this.navWidth = 1200;
  //     } else {
  //       this.isHome = false;
  //       // this.navWidth = 1470;
  //     }
  //   }
  // },
  methods: {
   openLoginFun(){
      this.$refs.header_nav.openLoginFun();
   }
  }
};
</script>
<style lang="scss">
@import url("./assets/font/font.css");
@import url("./assets/detail/index.css");


html,body{
  
  font-family: FZ
}
// 全局样式表
body {
  background: url("./assets/bg.png");
}
#app {
  background: url("./assets/bg.png");
  min-width: 1250px;
}
a {
  text-decoration: none;
}

.main-page {
  margin: 0 auto;
  min-width: 1200px;
  overflow: hidden;
  // background: url("./assets/bg.png");
}
// 首页轮播的样式
.home-bullet {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 100%;
  background: #ededed;
  opacity: 0.8;
  margin: 0 8px;
}
.home-bullet-active {
  background: #f9c10b;
}

//
.demo-ruleForm {
  .el-form-item {
    margin-bottom: 44px;
  }
}

.el-form-item__content {
  position: relative;
  .required {
    position: absolute;
    margin: 0;
    height: 55px;
    line-height: 55px;
    top: 0;
    right: -15px;
  }
}
.main_code {
  .el-input {
    width: 250px;
    float: left;
  }
  .el-input__inner {
    width: 250px;
    float: left;
  }
  .button {
    width: 140px;
    float: right;
    height: 53px;
    line-height: 53px;
    text-align: center;
    color: #c1c1c1;
    background: none;
    outline: none;
    border-radius: 8px;
    border: 1px solid #cacaca;
    font-size: 18px;
    cursor: pointer;
  }
}
.el-input__inner {
  width: 410px;
  height: 55px;
  line-height: 55px;
  border: 1px solid #cacaca;
  font-size: 18px;
  padding: 0 10px;
  border-radius: 8px;
  box-sizing: border-box;
  display: block;
  color: #999999;
  &:focus {
    border-color: #000;
  }
}
.dialog-wrap {
  border-radius: 8px;
  .el-dialog__close {
    font-size: 40px;
    transition: all 0.2s;
    &:hover {
      color: #000;
      transform: rotate(90deg);
    }
  }
}

.message-dialog {
  .el-dialog__header {
    line-height: 1;
  }
  .el-dialog__body {
    line-height: 1;
  }
  .el-dialog__close {
    font-size: 30px;
    transition: all 0.2s;
    &:hover {
      color: #000;
      transform: rotate(90deg);
    }
  }
}
.el-radio-group {
  padding-left: 35px;
  .el-radio:first-child {
    margin-right: 160px;
  }
}
.el-radio {
  color: #cecdcd;

  .el-radio__input.is-checked + .el-radio__label {
    color: #000;
  }
  .el-radio__input.is-checked .el-radio__inner {
    border-color: #000;
    background: #000;
  }
}
.is-checked {
  color: #000;
}

// menu
* {
  outline: none;
}
.el-message .el-message--success {
  z-index: 9999999999 !important;
}
.content .el-loading-spinner i {
  color: rgb(255, 246, 0) !important;
}
.login-wrap .el-loading-spinner i {
  color: rgb(0, 0, 0) !important;
  font-size: 90px;
}

.await {
  position: absolute;
  transform-origin: center center;
  z-index: 1;
  width: 100%;
  float: left;
  backdrop-filter: blur(6px);
  background: rgba(0, 0, 0, 0.15);
  height: 100%;
  top: 0;
  font-size: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
#enroll_detail {
  font-size: 16px !important;
  font-family: FZ !important;
  p {
    font-size: 16px !important;
    font-family: FZ !important;
    span {
      font-size: 16px !important;
      font-family: FZ !important;
    }
  }
  span {
    font-size: 16px !important;
    font-family: FZ !important;
    span {
      font-size: 16px !important;
      font-family: FZ !important;
    }
  }
}
</style>

