import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home/index.vue';

Vue.use(Router);

const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to,from,savedPosition){
    if(savedPosition){
      return savedPosition;
    }else{
      return {x:0,y:0}
    }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/About/index.vue'),
    },
    {
      path: '/activity',
      name: 'activity',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Activity/index.vue'),
      children: [
        {
          path: 'slider1/:itemId/:columnId', name: 'slider1', component: () => import(/* webpackChunkName: "about" */ './views/Activity/PageOne/index.vue'),
        },
        {
          path: 'slider2/:itemId/:columnId', name: 'slider2', component: () => import(/* webpackChunkName: "about" */ './views/Activity/PageTwo/index.vue'),
          children: [
            { path: 'tab1', name: 'tab1', component: () => import(/* webpackChunkName: "about" */ './views/Activity/PageTwo/TabOne/index.vue') },
            { path: 'tab2', name: 'tab2', component: () => import(/* webpackChunkName: "about" */ './views/Activity/PageTwo/TabTwo/index.vue') },
            { path: 'tab3', name: 'tab3', component: () => import(/* webpackChunkName: "about" */ './views/Activity/PageTwo/TabThree/index.vue') },
            { path: 'download/:id', name: 'download', component: () => import(/* webpackChunkName: "about" */ './views/Activity/PageTwo/DownLoad/index.vue') },

          ],
        },
        {
          path: 'slider3/:itemId/:columnId', name: 'slider3', component: () => import(/* webpackChunkName: "about" */ './views/Activity/PageThree/index.vue'),
          children: [
            { path: 'tab1', name: 'tab31', component: () => import(/* webpackChunkName: "about" */ './views/Activity/PageThree/TabOne/index.vue') },
            { path: 'tab2', name: 'tab32', component: () => import(/* webpackChunkName: "about" */ './views/Activity/PageThree/TabTwo/index.vue') },
            { path: 'tab3', name: 'tab33', component: () => import(/* webpackChunkName: "about" */ './views/Activity/PageThree/TabThree/index.vue') },
            { path: 'download/:id', name: 'download3', component: () => import(/* webpackChunkName: "about" */ './views/Activity/PageThree/DownLoad/index.vue') },

          ],
        },
        {
          path: 'slider4/:itemId/:columnId', name: 'slider4', component: () => import(/* webpackChunkName: "about" */ './views/Activity/PageFour/index.vue'),

          children: [
            { path: 'tab1', name: 'tab41', component: () => import(/* webpackChunkName: "about" */ './views/Activity/PageFour/TabOne/index.vue') },
            { path: 'tab2', name: 'tab42', component: () => import(/* webpackChunkName: "about" */ './views/Activity/PageFour/TabTwo/index.vue') },
            { path: 'tab3', name: 'tab43', component: () => import(/* webpackChunkName: "about" */ './views/Activity/PageFour/TabThree/index.vue') },
            { path: 'download/:id', name: 'download4', component: () => import(/* webpackChunkName: "about" */ './views/Activity/PageFour/DownLoad/index.vue') },

          ],
        },
        {
          path: 'slider5/:itemId/:columnId', name: 'slider5', component: () => import(/* webpackChunkName: "about" */ './views/Activity/PageFive/index.vue'),

          children: [
            { path: 'tab1', name: 'tab51', component: () => import(/* webpackChunkName: "about" */ './views/Activity/PageFive/TabOne/index.vue') },
            { path: 'tab2', name: 'tab52', component: () => import(/* webpackChunkName: "about" */ './views/Activity/PageFive/TabTwo/index.vue') },
            { path: 'tab3', name: 'tab53', component: () => import(/* webpackChunkName: "about" */ './views/Activity/PageFive/TabThree/index.vue') },
            { path: 'download/:id', name: 'download5', component: () => import(/* webpackChunkName: "about" */ './views/Activity/PageFive/DownLoad/index.vue') },

          ],
        },
        { path: 'detail/:columnId/:newsId', name: 'detail2', component: () => import(/* webpackChunkName: "about" */ './views/Activity/PageDetail/index.vue') },
        { path: 'detail/:itemId/:columnId/:newsId', name: 'detail', component: () => import(/* webpackChunkName: "about" */ './views/Activity/PageDetail/index.vue') },
        // { path: 'register', component: register }
      ]
    },
    {
      path: '/enroll',
      name: 'enroll',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Enroll/index.vue'),
      children: [
        {
          path: 'slider1/', name: 'enrollSlider1', props:{type:1} ,component: () => import(/* webpackChunkName: "about" */ './views/Enroll/PageOne/index.vue'),
        },
        {
          path: 'slider2/', name: 'enrollSlider2', props:{type:2},component: () => import(/* webpackChunkName: "about" */ './views/Enroll/PageTwo/index.vue'),
        },
        {
          path: 'slider3/', name: 'enrollSlider3', props:{type:3},component: () => import(/* webpackChunkName: "about" */ './views/Enroll/PageThree/index.vue'),
        },


        // { path: 'register', component: register }
      ]
    },
    {
      path: '/works',
      name: 'works',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Works/index.vue'),
    },
    {
      path: '/works/detail/:workId',
      name: 'worksDetail',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Works/Deatil/index.vue'),
    },
    {
      path: '/partner',
      name: 'partner',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Partner/index.vue'),
    },
    {
      path: '/partner/detail/:columnId/:newsId',
      name: 'partnerDetail',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Partner/Detail/index.vue'),
    },
    {
      path: '/await2',
      name: 'await2',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Await/index.vue'),
    },
    {
      path: '/history',
      name: 'history',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/History-v2/index.vue'),
      children: [
        {
          path: '/history/detail/:columnId/:newsId', name: 'historyDetail' ,component: () => import(/* webpackChunkName: "about" */ './views/History/Detail/index.vue'),
        },
        {
          path: '/history/list/:period', name: 'historyList' ,component: () => import(/* webpackChunkName: "about" */ './views/History-v2/List/index.vue'),
        }
      ],
      redirect:'/history/list/0'
    },
    // {
    //   path: '/history/detail/:columnId/:newsId',
    //   name: 'historyDetail',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ './views/History/Detail/index.vue'),
    // },
    {
      path: '/business',
      name: 'business',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Business/index.vue'),
    },
    {
      path: '/await4',
      name: 'await4',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Await/index.vue'),
    },
    {
      path: '/protocol',
      name: 'protocol',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/Protocol/index.vue'),
    },
  ],
});
