<template>
  <div class="top-part" v-if="list !== undefined && list.length >0">
    <el-card class="box-card">
      <div class="detail-info">
        <router-link class="more" to="/activity">{{$t('others.VMORE')}}</router-link>
        <div class="detail-info-img">
          <swiper :options="swiperOption">
            <swiper-slide v-for="(item,index) in list" :key="index">
              <a target="_blank" :href="item.obj_id ?item.obj_id :''">
                <div class="swiper-wrap"  v-lazy:background-image="item.url" ></div>
              </a>
            </swiper-slide>
            <img v-if="!(list !== undefined && list.length >0)" src="../../../../assets/home_top.jpg" alt />
          </swiper>
        </div>
        <div class="detail-info-text">
          <a
            class="detail"
            :href=" content.obj_id!=='' ?content.obj_id :''"
            target="_blank"
          >
            <h2
              :class="{'no-more': content.title!==''? (content.title && content.title.length)>=65:false}"
            >{{(content.title!=='undefined' && content.title!=='')?content.title : 'A World Tour Of Culture,Perception Of Cehngdu.A World Tour Of Culture,Perception Of Cehngdu.'}}</h2>
          </a>
          <h3
            :class="{'no-more':content.mark!==''? (content.mark && content.mark.length) >=55:false}"
          >{{(content.mark!=='undefined' &&  content.mark!=='')?content.mark : '“中华文化世界行·感知成都·设计之旅”系列活动系列活动系列活动系列活动系列活动系列活动系列活动系列活动系列活动系列活动系列活动系列活动系列活动系列活动系列活动系列活动系列活动' }}</h3>
          <a class="detail" :href="content.obj_id!==''?content.obj_id:''" target="_blank">
            <p>{{$t('navbar.ACTIVITY')}}</p>
          </a>
          <div class="pagination">
            <div class="prev"></div>
            <div class="next"></div>
          </div>
        </div>
      </div>
    </el-card>
    <div class="await" v-if="!(list !== undefined && list.length >0)">{{$t('others.CSOON')}}</div>
  </div>
</template>

<script>
import _ from "lodash";
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { http } from "@/api/http";

export default {
  name: "top_part",
  data() {
    let _this = this;
    return {
      swiperOption: {
        // pagination: {
        //   el: ".swiper-pagination",
        //   bulletActiveClass: "home-bullet-active",
        //   bulletClass: "home-bullet",
        //   clickable: true
        // },
        navigation: {
          nextEl: ".next",
          prevEl: ".prev"
        },
        speed: 2000,
        autoplay: {
          delay: 5000
        },
        on: {
          slideChangeTransitionStart: function() {
            _this.content = _this.list[this.activeIndex];
          }
        }
      },
      content: {
        title: "",
        obj_id: "",
        mark: ""
      },
      list: []
    };
  },
  components: { swiper, swiperSlide },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      this.$emit("toggleLoading", true);
      let _requese = {
        position: 2
      };
      let { data } = await http.get("/banner", _requese);
      this.list = data;
      this.content = data[0];
    },
    checkNull(object, item) {
      return _.includes(object, item);
    }
  }
};
</script>
<style lang="scss" scoped>
.top-part {
  position: relative;
  .box-card {
    border-radius: 0;
    .detail-info {
      padding: 110px 60px;
      overflow: hidden;
      position: relative;
      .more {
        position: absolute;
        right: 20px;
        top: 20px;
        font-size: 18px;
        padding-right: 15px;
        background: url("../../../../assets/more_arrow.png") no-repeat center
          right;
        background-size: 10% 85%;
        cursor: pointer;
        color: #000;
        &:hover {
          color: #747474;
        }
      }
      &-img {
        float: left;
        width: 500px;
        height: 300px;
        overflow: hidden;
        .swiper-wrap {
          width: 500px;
          height: 300px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }
        img {
          width: 100%;
        }
      }
      &-text {
        width: 460px;
        height: 300px;
        float: right;
        position: relative;
        h2 {
          margin: 0 0 5px;
          font-size: 34px;
          color: #4f4f4f;
          max-height: 118px;
          overflow: hidden;
          position: relative;
        }
        .no-more {
          &::after {
            content: "...";
            content: "...";
            position: absolute;
            bottom: 1px;
            right: 0;
            padding-left: 0px;
            background: #fff;
          }
        }
        h3 {
          margin: 0 0 24px;
          font-size: 17px;
          color: #414141;
          line-height: 1.5;
          max-height: 50px;
          overflow: hidden;
          position: relative;
        }
        .detail {
          overflow: hidden;
          cursor: pointer;
          p {
            font-size: 20px;
            font-weight: bolder;
            color: #414141;
            float: left;
            margin: 0;
            transition: all 800ms;
            &:hover {
              &::after {
                content: "";
                border-color: #fff;
              }
            }
            &::after {
              content: "";
              display: block;
              border-bottom: 4px solid #414141;
              margin-top: 4px;
              transition: all 200ms;
            }
          }
        }
        .pagination {
          height: 50px;
          position: absolute;
          right: 0;
          bottom: 0;
          div {
            display: inline-block;
            width: 55px;
            height: 50px;
            cursor: pointer;
          }
          .prev {
            background: url("../../../../assets/left_arrow.png") no-repeat
              center center #000;
            background-size: 20% 50%;
            margin-right: 20px;
          }
          .next {
            background: url("../../../../assets/right_arrow.png") no-repeat
              center center #000;
            background-size: 20% 50%;
          }
        }
      }
    }
  }
}
</style>
