
<template>
  <div>
    <div v-if="!isLogin">
      <el-dialog
        :visible="dialogState"
        width="530px"
        custom-class="dialog-wrap"
        :close-on-click-modal="false"
        @close="closeLogin"
      >
        <el-dialog
          :title="$t('others.NOTICE')"
          width="300px"
          :visible.sync="messageState"
          top="400px"
          custom-class="message-dialog"
        >{{messageContent}}</el-dialog>
        <div
          v-if="openLogin"
          class="login-wrap"
          v-loading="loading"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(255, 255, 255, 0.5)"
          element-loading-customClass="loading-z"
        >
          <div class="close"></div>
          <h2>{{$t('main.LOGIN')}}</h2>
          <p class="h3">
            <span @click="openLoginFun(false)">{{$t('main.NUCNA')}} <strong>{{$t('main.REGx')}}</strong></span>
          </p>
          <el-form
            :model="ruleForm"
            status-icon
            :rules="rules"
            ref="ruleForm"
            class="demo-ruleForm"
          >
            <el-form-item label prop="phone">
              <el-input
                type="phoone"
                v-model="ruleForm.phone"
                autocomplete="off"
                autofocus
                :placeholder="$t('main.PHONE')"
              ></el-input>
            </el-form-item>
            <el-form-item label prop="password">
              <el-input
                type="password"
                v-model="ruleForm.password"
                autocomplete="off"
                :placeholder="$t('main.PWD')"
              ></el-input>
            </el-form-item>

            <el-form-item>
              <input type="button" class="submit" @click="submitLogin('ruleForm')" :value="$t('main.LOGIN')" />
            </el-form-item>
          </el-form>
          <!-- <p class="forget">{{$t('main.FPWD')}}</p> -->
          <p class="tip">
             <a href='http://cdcdw.cdsbtz.com/protocol' target="_blank">{{$t('main.ATRT')}}</a>
          </p>
        </div>
        <div
          v-else
          class="login-wrap"
          v-loading="loading"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(255, 255, 255, 0.5)"
          element-loading-customClass="loading-z"
        >
          <h2>{{$t('main.REG')}}</h2>
          <p class="h3">
            <span @click="openLoginFun(true)">{{$t('main.ASUPLI')}} <strong>{{$t('main.LOGINx')}}</strong> </span>
          </p>
          <el-form
            :model="ruleForm"
            status-icon
            :rules="rules"
            ref="ruleForm"
            class="demo-ruleForm"
          >
            <el-form-item prop="phone">
              <el-input
                type="phone"
                v-model="ruleForm.phone"
                autocomplete="off"
                autofocus
                :placeholder="$t('main.PTYPNHFL')"
              ></el-input>
              <p class="required">*</p>
            </el-form-item>

            <el-form-item prop="verifiable_code">
              <div class="main_code">
                <el-input
                  type="verifiable_code"
                  maxlength="6"
                  v-model="ruleForm.verifiable_code"
                  autocomplete="off"
                  :placeholder="$t('main.PTTVCH')"
                ></el-input>
                <input
                  type="button"
                  class="button"
                  @click="sendCode"
                  :value="codeBtn.content"
                  :disabled="!codeBtn.canClick"
                />
              </div>
              <p class="required">*</p>
            </el-form-item>

            <el-form-item prop="password">
              <el-input
                type="password"
                v-model="ruleForm.password"
                autocomplete="off"
                :placeholder="$t('main.PTAPH')"
              ></el-input>
              <p class="required">*</p>
            </el-form-item>

            <el-form-item prop="repassword">
              <el-input
                type="password"
                v-model="ruleForm.repassword"
                autocomplete="off"
                :placeholder="$t('main.PRYPH')"
              ></el-input>
              <p class="required">*</p>
            </el-form-item>

            <el-form-item prop="name">
              <el-input
                type="text"
                v-model="ruleForm.name"
                autocomplete="off"
                :placeholder="$t('main.PTYNH')"
              ></el-input>
              <p class="required">*</p>
            </el-form-item>

            <el-form-item prop="industry">
              <el-input
                type="text"
                v-model="ruleForm.industry"
                autocomplete="off"
                :placeholder="$t('main.PTYFOE')"
              ></el-input>
              <p class="required">*</p>
            </el-form-item>

            <el-form-item prop="email">
              <el-input
                type="text"
                v-model="ruleForm.email"
                autocomplete="off"
                :placeholder="$t('main.PTYEAH')"
              ></el-input>
            </el-form-item>

            <el-form-item prop="role">
              <el-radio-group v-model="ruleForm.role" text-color="#000000">
                <el-radio label="4">{{$t('others.INDIVIDUAL')}}</el-radio>
                <el-radio label="5">{{$t('others.COMPANY')}}</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item prop="tel">
              <el-input
                type="phone"
                v-model="ruleForm.tel"
                autocomplete="off"
                :placeholder="$t('main.PTPPN')"
              ></el-input>
              <p class="required">*</p>
            </el-form-item>

            <el-form-item label prop="license">
              <el-input
                type="text"
                v-model="ruleForm.license"
                autocomplete="off"
                :placeholder="$t('main.PTYINOBLN')"
              ></el-input>
            </el-form-item>

            <el-form-item v-if="ruleForm.role==5" label prop="company">
              <el-input
                type="text"
                v-model="ruleForm.company"
                autocomplete="off"
                :placeholder="$t('main.PTPYCN')"
              ></el-input>
              <p class="required">*</p>
            </el-form-item>

            <el-form-item>
              <input type="button" class="submit" @click="submitReg('ruleForm')" :value="$t('main.LOGIN')" />
            </el-form-item>
          </el-form>
          <p class="tip">
            <a href='http://cdcdw.cdsbtz.com/protocol' target="_blank">{{$t('main.ATRT')}}</a>
          </p>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Api from "@/api";
import { MessageBox } from "element-ui";
import { mapState } from "vuex";
export default {
  name: "login",

  data() {
    const validatePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t('main.PTYPNHFL')));
      } else if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error(this.$t('main.PTYCPN')));
      } else {
        callback();
      }
    };
    const validatePass = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$t('main.PTAPH')));
      } else {
        if (this.ruleForm.repassword !== "") {
          this.$refs.ruleForm.validateField("repassword");
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$t('main.PRYPH')));
      } else if (value !== this.ruleForm.password) {
        callback(new Error(this.$t('main.YPACPDNM')));
      } else {
        callback();
      }
    };
    const validateName = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$t('main.PTYNH')));
      } else {
        callback();
      }
    };
    const validateTel = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$t('main.PTPPN')));
      } else {
        callback();
      }
    };
    const validateIndustry = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$t('main.PTYFOE')));
      } else {
        callback();
      }
    };
    const validateCompany = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$t('main.PTYCN')));
      } else {
        callback();
      }
    };
    const validateRole = (rule, value, callback) => {
      if (this.ruleForm.role == 5 && !value) {
        callback(new Error(this.$t('main.IOC')));
      } else {
        callback();
      }
    };

    return {
      loading: false,
      messageContent: "",
      messageState: false,
      ruleForm: {
        verifiable_type: "sms"
      },
      rules: {
        phone: [{ validator: validatePhone, trigger: "blur" }],
        verifiable_code: [
          { required: true, message: this.$t('main.PTTVCH'), trigger: "blur" }
        ],
        password: [{ validator: validatePass, trigger: "blur" }],
        repassword: [{ validator: validatePass2, trigger: "blur" }],
        name: [{ validator: validateName, trigger: "blur" }],
        tel: [{ validator: validateTel, trigger: "blur" }],
        industry: [{ validator: validateIndustry, trigger: "blur" }],
        email: [
          {
            type: "email",
            message: this.$t('main.PTYCEA'),
            trigger: ["blur", "change"]
          }
        ],
        role: [{ validator: validateRole, trigger: "change" }],
        company: [{ validator: validateCompany, trigger: "blur" }]
      },
      codeBtn: {
        content: this.$t('main.SAVC'),
        totalTime: 60,
        canClick: true
      }
    };
  },
  created() {
    let _isLogin = JSON.parse(localStorage.getItem("hasLogin"));
    let _userName = JSON.parse(localStorage.getItem("userName"));
    if (_isLogin) {
      this.$store.commit("changeLoginState", true);
      this.$store.commit("changeUsername", _userName);
    } else {
    }
  },
  components: {},
  computed: {
    ...mapState(["openLogin", "dialogState", "isLogin", "userName"])
  },
  methods: {
    openLoginFun(state) {
      this.$store.commit("openLogin", state);
    },

    closeLogin() {
      this.$store.commit("closeLogin", false);
    },

    openMessage(message) {
      this.messageContent = message;
      this.messageState = true;
    },

    sendCode(phone) {
      if (this.ruleForm.phone === "") return false;
      let data = { phone: this.ruleForm.phone };
      Api.sendCode(data).then(res => {
        if (res.code) {
          this.openMessage(res.message);
        } else {
          this.getCode();
        }
      });
    },

    getCode() {
      if (!this.ruleForm.phone || this.ruleForm.phone === "") return false;
      if (!this.codeBtn.canClick) return;
      this.codeBtn.canClick = false;
      this.codeBtn.content = this.$t('main.RLATERA') + this.codeBtn.totalTime + this.$t('main.RLATERB');
      let clock = window.setInterval(() => {
        this.codeBtn.totalTime--;
        this.codeBtn.content = this.$t('main.RLATERA') + this.codeBtn.totalTime + this.$t('main.RLATERB');
        if (this.codeBtn.totalTime < 0) {
          window.clearInterval(clock);
          this.codeBtn.content = this.$t('main.RAVC');
          this.codeBtn.totalTime = 60;
          this.codeBtn.canClick = true;
        }
      }, 1000);
    },

    submitReg(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.loading = true;
          Api.reg(this.ruleForm).then(res => {
            this.loading = false;
            if (res.code) {
              this.openMessage(res.message);
            } else {
              this.openLoginFun(true);
            }
          });
        } else {
          return false;
        }
      });
    },

    submitLogin(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.loading = true;
          Api.login({
            phone: this.ruleForm.phone,
            password: this.ruleForm.password
          }).then(res => {
            this.loading = false;
            if (res.code) {
              this.openMessage(res.message);
            } else {
              this.$store.dispatch("changeLoginAction", {
                loginState: true,
                access_token: res.data.access_token
              });
              Api.getUser(res.data).then(user => {
                this.$store.dispatch("changeUsernameAction", user.name);
                // this.$emit("loginSuccess", true, user.name);
              });
            }
          });
        } else {
          return false;
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.loading-z {
  z-index: 999999999999;
}
.el-loading-spinner i {
  color: rgb(0, 0, 0) !important;
}
.reg {
  font-size: 20px;
  display: inline-block;
  cursor: pointer;
  transition: 300ms all;
  &:hover {
    color: #8a8a8a;
  }
}

.is-login {
  position: relative;
  cursor: pointer;
  font-size: 18px;

  p {
    width: 120px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 0;
    font-size: 18px;
    color: #000;
    float: left;
  }
  i {
    position: absolute;
    right: -5px;
    height: 90px;
    top: 0;
    line-height: 90px;
    color: #000;
  }
}
.w120 {
  width: 120px;
}

.line {
  font-size: 20px;
  color: #a1a1a1;
  margin: 0 20px;
}

.login-wrap {
  padding: 40px;
  padding-top: 10px;
  padding-bottom: 0;

  h2 {
    margin: 0;
    font-size: 24px;
    font-weight: bolder;
    line-height: 1;
  }
  .h3 {
    margin: 18px 0 40px;
    font-size: 19px;
    line-height: 1;
    span {
      margin-left: 5px;
      cursor: pointer;
      &:hover {
        color: #000;
      }
    }
  }
  .submit {
    width: 410px;
    height: 55px;
    line-height: 55px;
    text-align: center;
    background: #000;
    color: #fff;
    border-radius: 8px;
    font-size: 19px;
    outline: none;
    cursor: pointer;
    border: none;
  }
  .forget {
    font-size: 12px;
    text-decoration: underline;
    color: #2a3fd7;
    line-height: 1;
    text-align: right;
    margin: 0;
    cursor: pointer;
    margin-top: -8px;
  }
  .tip {
    margin-top: 36px;
    text-align: center;
    line-height: 1;
    font-size: 12px;
    color: #d0d0d0;
    a {
      color: #5d5d5d;
      cursor: pointer;
    }
  }
}
</style>


