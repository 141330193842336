<template>
  <div
    class="header-main"
    :class="{
      'show-header-body': !hiddenHeader,
      'hidden-header-body': hiddenHeader,
      'header-to-top': headerToTop,
    }"
  >
    <div class="placeholder"></div>
    <div
      class="header-wrap"
      :class="{ 'has-nav': hasNav, 'hidden-header': hiddenHeader }"
    >
      <router-link to="/">
        <div class="logo-wrap">
          <div class="logo zoom"></div>
          <div class="title zoom">
            <p>成都创意设计周</p>
            <p>Chengdu Creativity & Design Week</p>
          </div>
        </div>
      </router-link>
      <transition name="fade">
        <div
          v-if="hasNav"
          class="nav"
          :class="{ EN: !isLangZh, 'en-a': !isLangZh }"
        >
          <router-link active-class="router-active" to="/activity">{{
            $t('navbar.ACTIVITY')
          }}</router-link>
          <router-link active-class="router-active" to="/enroll">{{
            $t('navbar.ENROLL')
          }}</router-link>
          <a active-class="router-active" target="_blank" href="https://gptcda.cdcdw.com.cn/portal/product/index.html">{{
            $t('navbar.APPRECIATE')
          }}</a>
          <router-link active-class="router-active" to="/history">{{
            $t('navbar.HISTORY')
          }}</router-link>
          <!-- <router-link active-class="router-active" to="/await4">{{$t('navbar.PANORAMIC')}}</router-link> -->
          <a href="https://www.720yun.com/vr/52cjkrtnek5" target="_blank">{{
            $t('navbar.PANORAMIC')
          }}</a>
        </div>
      </transition>
      <div class="lang">
        <div @click="changeLang('zh')" :class="{ 'lang-actived': isLangZh }">
          {{ $t('lang.CH') }}
        </div>
        <div @click="changeLang('en')" :class="{ 'lang-actived': !isLangZh }">
          {{ $t('lang.EN') }}
        </div>
      </div>
      <div class="login" :class="{ 'en-login': !isLangZh }">
        <div v-if="isLogin" class="is-login" :class="{ EN: !isLangZh }">
          <p :class="{ EN: !isLangZh }">
            {{ $t('navbar.HELLO') }}{{ userName }}
          </p>
          <span style="margin-left: 2px" @click="logoutFun">{{
            $t('main.SOUT')
          }}</span>
        </div>
        <div v-else :class="{ EN: !isLangZh }">
          <div
            class="reg"
            :class="{ EN: !isLangZh }"
            @click="openLoginFun(false)"
          >
            {{ $t('main.REG') }}
          </div>
          <span class="line" :class="{ EN: !isLangZh }">|</span>
          <div
            class="reg"
            :class="{ EN: !isLangZh }"
            @click="openLoginFun(true)"
          >
            {{ $t('main.LOGIN') }}
          </div>
        </div>
      </div>

      <div class="nav-replace">
        <div
          class="icon"
          @click="openNav ? toggleNav(false) : toggleNav(true)"
          :class="{ 'icon-active': openNav }"
        >
          <span></span>
          <span></span>
          <span></span>
        </div>

        <div class="nav-body" :class="{ 'nav-body-active': openNav }">
          <div class="login-replace">
            <div v-if="isLogin" class="is-login">
              <p>{{ $t('navbar.HELLO') }}{{ userName }}</p>
              <span style="margin-left: 2px" @click="logoutFun">{{
                $t('main.SOUTx')
              }}</span>
            </div>
            <div v-else>
              <div class="reg" @click="openLoginFun(false)">
                {{ $t('main.REGx') }}
              </div>
              <span class="line">|</span>
              <div class="reg" @click="openLoginFun(true)">
                {{ $t('main.LOGINx') }}
              </div>
            </div>
          </div>
          <div class="nav-body-inner" @click="openNav = false">
            <router-link active-class="router-active1" to="/activity">{{
              $t('navbar.ACTIVITY')
            }}</router-link>
            <router-link active-class="router-active1" to="/enroll">{{
              $t('navbar.ENROLL')
            }}</router-link>
            <router-link active-class="router-active1" to="/works">{{
              $t('navbar.APPRECIATE')
            }}</router-link>
            <router-link active-class="router-active1" to="/await3">{{
              $t('navbar.HISTORY')
            }}</router-link>
            <a href="https://720yun.com/t/7fvkimf9g7m" target="_blank">{{
              $t('navbar.PANORAMIC')
            }}</a>
          </div>
          <div
            class="nav-circle"
            :class="{
              'nav-circle-active': openNav1,
              'nav-circle-close': openNav2,
            }"
          ></div>
        </div>
      </div>
    </div>
    <Login ref="login" :isLangZh="isLangZh" />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Login from './Login';

export default {
  name: 'header_nav',
  data() {
    return {
      hiddenHeader: false,
      hasNav: false,
      headerToTop: true,
      isHome: true,
      openNav: false,
      openNav1: false,
      openNav2: false,
      lang: 'zh',
    };
  },
  computed: {
    ...mapState(['isLogin', 'userName', 'isLangZh']),
  },
  watch: {
    $route(to, from) {
      window.scroll(0, 1);
      if (to.name === 'home') {
        this.isHome = true;
        this.headerToTop = true;
        this.hiddenHeader = false;
        this.hasNav = false;
      } else {
        this.isHome = false;
        this.hasNav = true;
      }
    },
  },

  components: { Login },
  mounted() {
    window.addEventListener('scroll', this.scrollToTop, false);
  },
  methods: {
    toggleNav(state) {
      if (state) {
        this.openNav = state;
        this.stop();
        setTimeout(() => {
          this.openNav1 = true;
          this.openNav2 = false;
        }, 200);
      } else {
        this.openNav1 = false;
        this.openNav2 = true;
        setTimeout(() => {
          this.openNav2 = false;
          this.openNav = false;
        }, 300);
        this.move();
      }
    },
    stop() {
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = 'hidden';
      document.addEventListener('touchmove', mo, false); //禁止页面滑动
    },
    /***取消滑动限制***/
    move() {
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = ''; //出现滚动条
      document.removeEventListener('touchmove', mo, false);
    },

    changeLang(lang) {
      // this.$store.commit("changeLang", lang === "zh");
      // this.$i18n.locale = lang;
      let url =
        lang === 'zh' ? 'http://www.cdcdw.com.cn/' : 'http://en.cdcdw.com.cn/';
      window.open(url);
    },

    openLoginFun(state) {
      if (state) {
        window.open(
          'https://gptcda.cdcdw.com.cn/user/register/login.html',
          '_blank'
        );
      } else {
        window.open(
          'https://gptcda.cdcdw.com.cn/user/register/register.html',
          '_blank'
        );
      }
      // this.$store.commit("openLogin", state);
    },

    logoutFun() {
      this.$store.dispatch('logoutAction', false);
    },

    scrollToTop() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let scroll = scrollTop - this.scroll;
      this.scroll = scrollTop;
      this.headerToTop = scrollTop <= 95;
      this.hiddenHeader = scroll >= 1;
      if (this.isHome) {
        if (scrollTop >= 900) {
          this.hasNav = true;
        } else {
          this.hasNav = false;
        }
      } else {
        this.hasNav = true;
      }
    },
  },
  beforeDestroy() {
    this.move();
    window.removeEventListener('scroll', this.scrollToTop, false); //离开当前组件别忘记移除事件监听哦
  },
};
</script>
<style lang="scss" scoped>
.EN {
  font-size: 16px;
}
@media screen and (max-width: 1250px) {
  .EN {
    font-size: 13px !important;
  }
  .show-header-body {
    .EN {
      a {
        font-size: 13px !important;
      }
    }
  }
  .show-header-body {
    .login {
      font-size: 13px !important;
      .is-login {
        font-size: 13px;
        .EN {
          p {
            font-size: 13px !important;
            width: 90px;
          }
          span {
            font-size: 13px !important;
          }
        }
      }
    }
  }
}
.router-active {
  color: #a1a1a1 !important;
}

@media screen and (max-width: 1060px) {
  .header-wrap {
    box-sizing: border-box;
    .logo-wrap {
      position: relative;
      left: 50%;
      transform: translateX(-50%) !important;
    }
    .nav {
      display: none;
    }
    .login {
      display: none;
    }
  }
  .nav-replace {
    display: block !important;
  }
  .placeholder {
    min-width: 1060px;
  }
}

.header-main {
  .placeholder {
    height: 95px;
  }
  .header-wrap {
    position: fixed;
    z-index: 999;
    left: 0;
    right: 0;
    top: 0;
    padding: 0 40px;
    .logo-wrap {
      float: left;
    }
    .logo {
      height: 54px;
      width: 80px;
      float: left;
      margin-top: 24px;
      background: url('../../assets/logo.png') no-repeat;
      background-position: top center;
      background-size: cover;
    }
    .title {
      float: left;
      color: #000;
      font-size: 12px;
      margin: 24px 0 16px 18px;
      p {
        &:nth-child(1) {
          margin: 0;
          font-size: 27px;
          letter-spacing: 4px;
        }
        &:nth-child(2) {
          margin: 5px 0 0;
          letter-spacing: 0.25px;
        }
      }
    }
    .nav {
      float: left;
      font-size: 20px;
      margin: 38px 0 0 110px;
      a {
        color: #000;
        margin-right: 70px;
        transition: all 0.2s;
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          color: #8a8a8a;
        }
      }
    }

    .login {
      float: right;
      margin: 38px 80px 0 0;
      font-size: 20px;
    }

    .lang {
      margin: 34px 0 0;
      float: right;
      font-size: 16px;
      &-actived {
        background: #000 !important;
        color: #fff !important;
      }
      div {
        cursor: pointer;
        width: 58px;
        height: 28px;
        text-align: center;
        line-height: 28px;
        background: #f5f5f5;
        color: #fff;
        color: #8f8f8f;
        float: left;
        &:hover {
          background: #000;
          color: #fff;
        }
      }
    }
    .nav-replace {
      display: none;
      width: 25px;
      position: absolute;
      margin: 30px 0 0;
      .icon {
        position: relative;
        z-index: 1000;
        span {
          transition: all 0.3s;
          height: 4px;
          background: #000;
          width: 25px;
          display: block;
          &:nth-child(2) {
            margin: 6px 0;
          }
        }
      }
      .icon-active {
        span {
          background: #fff;
          &:nth-child(1) {
            transform: rotate(45deg) translateY(-2px) translateX(5px);
            transform-origin: top left;
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:nth-child(3) {
            transform: rotate(-45deg) translateX(4px) translateY(2px);
            transform-origin: bottom left;
          }
        }
      }

      .nav-body {
        display: none;
        transition: all 1s;
        width: 100vw;
        height: 100vh;
        position: relative;
        top: -58px;
        left: -40px;
        overflow: hidden;
        z-index: 99;
        background: #fff;
        color: #fff;
        .nav-circle {
          height: 0;
          width: 0;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 2;
          background: #000;
          transform: translate3d(-50%, -50%, 0);
        }
        .nav-circle-active {
          transition: all 0.5s;
          height: 5000px;
          width: 5000px;
        }
        .nav-circle-close {
          transition: all 0.2s;
          height: 0 !important;
          width: 0 !important;
          top: 0 !important;
        }

        &-inner {
          width: 250px;
          height: 400px;
          margin: 150px auto 0;
          font-size: 20px;
          position: relative;
          z-index: 3;
          color: #fff;

          a {
            display: block;
            color: #fff;
            margin: 20px 0;
            text-align: center;
            &:hover {
              color: #a1a1a1;
            }
          }
        }

        .login-replace {
          color: #fff !important;
          text-align: center;
          margin-top: 50px;
          position: relative;
          z-index: 3;
          font-size: 20px;
          .line {
            color: #fff;
          }

          .is-login {
            width: 250px;
            margin: 0 auto;
            p {
              color: #fff;
              width: auto;
            }
          }
        }
      }
      .nav-body-active {
        display: block !important;
      }
    }

    @media screen and (max-width: 1540px) {
      .login {
        margin: 38px 30px 0 0;
      }
      .en-login {
        margin: 38px 20px 0 0;
      }
      .nav {
        margin: 38px 0 0 90px;
        a {
          margin-right: 50px;
        }
      }
      .en-a {
        a {
          margin-right: 20px;
        }
      }
    }
    @media screen and (max-width: 1200px) {
      .login {
        margin: 38px 20px 0 0;
      }
      .en-login {
        margin: 41px 20px 0 0;
      }
      .nav {
        margin: 38px 0 0 0px;
        a {
          margin-right: 20px;
        }
      }
      .en-a {
        a {
          margin-right: 16px;
        }
      }
    }
  }
}

.hidden-header {
  transition: all 1s;
  transform: translateY(-150px);
  .title {
    margin: 24px 0 16px 18px;
  }
  .nav {
    transform: translateX(0px);
  }
  .login {
    font-size: 16px !important;
  }
  .lang {
    font-size: 12px !important;
  }
}

.show-header-body {
  transition: all 3s;
  .header-wrap {
    transition: all 0.3s;
    height: 80px;
    top: -20px;
    .zoom {
      transition: all 0.3s;
      transform: scale(0.8);
      transform-origin: top left;
    }
    .title {
      margin: 24px 0 16px 0px;
    }
    .nav {
      // transform: translateX(-80px);
      // margin-left: 20
      margin: 38px 0 0 30px;
    }
    .nav-replace {
      margin-top: 34px;
    }
  }
  @media screen and (max-width: 1400px) {
    .logo-wrap {
      transform: scale(0.8) translateX(-35px);
    }
    .nav {
      font-size: 16px !important;
      margin-left: 0px !important;
      transform: translateX(0px) !important;
    }
    .login {
      font-size: 16px !important;
      .is-login {
        font-size: 16px;
        p {
          font-size: 16px;
          width: 90px;
        }
      }
    }
    .lang {
      font-size: 12px !important;
    }
  }
}

.header-to-top {
  transition: all 3s;
  .header-wrap {
    transition: all 0.3s;
    height: 95px;
    top: 0px;
    .zoom {
      transition: all 0.3s;
      transform: scale(1);
      transform-origin: top left;
    }
    .title {
      margin: 24px 0 16px 18px;
    }
    .nav {
      transform: translateX(0px);
    }
    .login {
      font-size: 20px;
    }
    .lang {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 1400px) {
    .logo-wrap {
      transition: all 0.3s;
      transform-origin: top left;
      transform: scale(0.8) translateX(10px);
    }
    .nav {
      font-size: 16px !important;
      margin-left: 0 !important;
    }
    .login {
      font-size: 16px !important;
    }
    .lang {
      font-size: 12px !important;
    }
  }
}

.has-nav {
  background: #fff;
  transition: all 0.4s;
}
.reg {
  display: inline-block;
  cursor: pointer;
  transition: 300ms all;
  &:hover {
    color: #8a8a8a;
  }
}
.line {
  color: #000;
  margin: 0 20px;
}
.is-login {
  position: relative;
  cursor: pointer;
  font-size: 18px;

  p {
    width: 120px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 0;
    font-size: 18px;
    color: #000;
    float: left;
  }
  i {
    position: absolute;
    right: -5px;
    height: 90px;
    top: 0;
    line-height: 90px;
    color: #000;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter, .fade-leave-to
/* .component-fade-leave-active for below version 2.1.8 */ {
  opacity: 0;
}
</style>
