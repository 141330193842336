<template>
  <div class="footer-wrap">
    <div class="top">
      <!-- <img src="../../assets/phone.png" alt />
      <p>{{$t('bottom.MWEB')}}</p>
      <p>{{$t('bottom.COFU')}}</p>
      <p>{{$t('bottom.PPOLICY')}}</p>
      <p>{{$t('bottom.IPCOMPLAINTS')}}</p>
      <p>{{$t('bottom.ESERVICES')}}</p>
      <p>{{$t('bottom.HELP')}}</p>
      <p>{{$t('bottom.CONTACTUS')}}</p>
      <p>{{$t('bottom.LUG')}}</p>
      <p>{{$t('bottom.EN')}}</p>-->
    </div>
    <div class="bottom">
      <a href="http://beian.miit.gov.cn" target="_blank">{{$t('bottom.IPC1')}}</a>
      <p>{{$t('bottom.IPC')}}</p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "footer_nav",
  components: {}
};
</script>
<style lang="scss" scoped>
.footer-wrap {
  height: 220px;
  background: url("../../assets/black_bg.jpg");
  color: #969696;
  position: relative;
  z-index: 99;
  .top {
    height: 148px;
    border-bottom: 2px solid #3d3d3d;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    img {
      height: 40px;
      width: 30px;
    }
    p {
      margin: 0 13px;
      cursor: pointer;
      border-bottom: 1px solid transparent;

      &:hover {
        border-bottom: 1px solid #a8a8a8;
      }
    }
  }
  .bottom {
    height: 70px;
    // line-height: 70px;
    a {
      width: 100%;
      display: block;
      text-align: center;
      margin-bottom: 10px;
      padding-top: 10px;
      color: #969696;
      font-size: 14px;
    }
    p {
      text-align: center;
      font-size: 14px;
      margin: 0;
    }
  }
}
</style>
