<template>
  <div class="home-wrap">
    <TopPart />
    <CenterPart />
    <BottomPart />
  </div>
</template>

<script>
import TopPart from "./components/TopPart";
import CenterPart from "./components/CenterPart";
import BottomPart from "./components/BottomPart";
export default {
  name: "home",
  components: { TopPart, CenterPart, BottomPart }
};
</script>
<style lang="scss" scoped>
.home-wrap {
  margin-top: -90px;
    width: 1200px;
    margin: 0 auto;

}
</style>
