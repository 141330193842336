export default {
  main: {
    LOGIN: 'Log in',
    REG: 'Sign up',
    LOGINx: 'Log In',
    REGx: 'Sign Up',
    NUCNA: 'New User？Create New Account',
    PHONE: 'Phone number',
    PWD: 'Password',
    ATRT: 'By logging in, you agree to receive Terms of Use and Privacy Policy of the portal.',
    ASUPLI: 'Already signed up? Log in',
    PTYPNHFL: 'Please type your phone number here for login',
    PTYCPN: 'Please type your correct phone number',
    PTTVCH: 'Please type the verification code here',
    SAVC: 'Send a verification code',
    PTAPH: 'Please type a password here',
    PRYPH: 'Please re-type your password here',
    PTYNH: 'Please type you name here',
    PTYFOE: 'Please type your field of expertise',
    PTYEAH: 'Please type your email address here',
    SOUT: 'Sign out',
    SOUTx: 'Sign Out',
    FPWD: 'Forgot password？',
    PTYINOBLN: 'Please type your ID number or business license number',
    PTPYCN: 'Please type your company name',
    YPACPDNM: 'Your password and confirmation password do not match',
    PTPPN: ' Please type your phone number',
    PTYCN: 'Please type your company name',
    IOC: 'Individual or company',
    PTYCEA: 'Please type your correct email address',
    RLATERA: 'Resend ',
    RLATERB: 's later',
    RAVC: 'Resend a verification code',
  },
  lang: {
    EN: 'EN',
    CH: 'CH',
  },
  navbar: {
    CDCDW: 'Chengdu Creativity & Design Week',
    HELLO: 'Hello,',
    HOME: 'Home',
    ABOUT: 'About',
    ENROLL: 'Registration',
    ACTIVITY: 'The Week',
    APPRECIATE: 'Archive',
    HISTORY: 'Review',
    PANORAMIC: '360° Panorama Gallery',
    DATESDEADLINES: 'Dates & Deadlines',
    PRESSROOM: 'Press Room',
    DOWNLOADS: 'Downloads',
    STORE: 'Store',
    GALLERY: 'Online Gallery',
    ARCHIVE: 'Archive',
    PARTNERS: 'Partners',
    RINFORMATION: 'Registration Information',
  },
  others: {
    ATTACHMENTS: 'Attachments',
    AFORM: 'Application Form',
    SFTOATPOA: 'Statement of the Original and the Power of Attorney',
    NMDIA: 'No more data is available.',
    LOADING: 'Loading',
    NOTICE: 'Notice',
    RMORE: 'Read more',
    VMORE: 'View more',
    INDIVIDUAL: 'Individual',
    COMPANY: 'Company',
    MOREW: 'More',
    CSOON: 'Coming soon',
  },
  business: {
    BPARTNERS: 'Partners',
  },
  deigndata: {
    DEIGNNAME: 'Deign Name',
    DESIGNNO: 'Design No.',
    DESIGNER: 'Designer',
    DATEOFDESIGN: 'Date of Design',
    INTRODUCTION: 'Introduction',
    IMAGES: 'Images',
  },
  test: {
    HOME: 'This is Home Page',
    ABOUT: 'This is About Page',
    ENROLL: 'This is Enroll Page',
    TIP: 'Click the lang button to try to switch languages',
  },
  activity: {
    FABUTIME: 'Time of Release',
    XGFUJIAN: 'Attachments',
    DJXIAZAI: 'Downloads',
    SYIPIAN: 'Previous',
    XYIPIAN: 'Next',
    TNAME: 'Name',
    MEDIA: 'Media',
  },
  works: {
    ALL: 'All',
  },
  about: {
    GYOUR: 'About Us',
    GYOURx: '',
    PREA: "According to the spirit of General Secretary Xi Jinping's important speeches in Sichuan and to fully implement the State Council's Opinions on Promoting the Integrated Development of Cultural Creativity and Design Services with Related Industries, Chengdu Municipal People's Government has held the Chengdu Creativity & Design Week since 2014 and gained great successes.",
    PREB: "During the past five years, Chengdu Creativity & Design Week has attracted about 3,000 creative design institutions and professional designers from over 30 countries and regions. It has collected more than 13,000 works and covered an exhibition area of 220,000 square meters. So far, over 150,000 designs have been exhibited to attract more than 2 million visitors. Creative design fever hits Chengdu in a big way. Chengdu plays a leading role in building national brands of creative design in Western and Central China. Through a wide application of new media and professional media, it further promotes Tianfu cultural communication at home and abroad, which focuses on creativity&originality, elegance&fashion, optimistic&inclusive, friendship&benevolence.",
    PREC: "According to the statistics released by Jinri Toutiao, Chengdu Creativity & Design Week is one of the top three events in terms of national influence. It has become an influential brand in Chengdu's creative design industry and laid a foundation for promoting the integration of cultural creativity and design services in Chengdu. It now leads the way in building Chengdu into a world city of cultural creativity.",
  },
  bottom: {
    MWEB: 'Mobile Website',
    COFU: 'Conditions of Use',
    PPOLICY: 'Privacy Policy',
    IPCOMPLAINTS: 'Intellectual Property Complaints',
    ESERVICES: 'Enterprise Services',
    HELP: 'Help',
    CONTACTUS: 'Contact Us',
    LUG: 'ZH',
    EN: 'English',
    IPC1: '蜀ICP备19013477号',
    IPC: ' Copyright  2005 - 2019 Chengdu Ithink Network Technology Co., Ltd. All Rights Reserved',
  },
  previewImg: {
    previewa: '不能再放大了哦！',
    previewb: '不能再缩小了哦！',
    previewc: '前面已经没有了！',
    previewd: '已经是最后一张了哦！',
  },
  session: {
    all: 'All',
    first: 'First Session',
    second: 'Second Session',
    third: 'Third Session',
    fourth: 'Fourth Session',
    fifth: 'Fifth Session',
    sixth: 'Sixth Session',
	seventh:'Seventh Session',
	eighth:'Eighth Session',
	ninth:'Ninth Session',
	tenth:'Tenth Session'
  }
};

