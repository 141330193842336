import Vue from 'vue';

import {
  Card, Input, Form, FormItem, Loading, Icon, Radio,
  RadioGroup, Dialog,InfiniteScroll,Backtop,Image
} from 'element-ui';
import 'element-ui/lib/theme-chalk/card.css';
import 'element-ui/lib/theme-chalk/input.css';
import 'element-ui/lib/theme-chalk/form.css';
import 'element-ui/lib/theme-chalk/form-item.css';
import 'element-ui/lib/theme-chalk/loading.css';
import 'element-ui/lib/theme-chalk/icon.css';
import 'element-ui/lib/theme-chalk/radio.css';
import 'element-ui/lib/theme-chalk/radio-group.css';
import 'element-ui/lib/theme-chalk/dialog.css';

import 'element-ui/lib/theme-chalk/backtop.css';
import 'element-ui/lib/theme-chalk/image.css';

import VueLazyload from 'vue-lazyload'

// Vue.use(VueLazyload)

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: './assets/error.png',
  loading: './assets/loading.gif',
  attempt: 1
})

import 'normalize.css'
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './lang';

import globalData from './seeting'

Vue.config.productionTip = false;
Vue.prototype.GLOBAL = globalData

Vue.use({
  i18n: (key, value) => i18n.t(key, value),
});


Vue.use(Card)
Vue.use(Input)
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Loading);
Vue.use(Icon);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Dialog);
Vue.use(InfiniteScroll);
Vue.use(Backtop);
Vue.use(Image);



new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
